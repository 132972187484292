<template>
    <el-container>
        <el-header height="auto">
            <Header></Header>
        </el-header>
        <el-container>
            <div>
                <router-view></router-view>
            </div>
        </el-container>
    </el-container>
</template>

<script>
    import Header from "c/Header";

    export default {
        name: "Index",
        components: {
            Header,
        },
        data() {
            return {}
        },
    }
</script>

<style scoped lang="scss">
    .el-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        background-color: #e8e8e8;

        .el-header {
            padding: 0;
        }

        .el-container {
            width: 100%;
            padding-top: 20px;

            > div {
                width: 100%;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
    }

</style>
