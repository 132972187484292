<template>
    <!--头部-->
    <div class="header-box">
        <div class="header-content">
            <div class="header-content-l">
                <img src="../assets/images/logo_blue.png"
                     alt=""
                     class="img_logo"
                />
                <ul>
                    <li>考点志愿省市选择及缴费</li>
                </ul>
            </div>
            <div class="header-content-r">
                <div class="info">姓名：{{$store.state.accountInfo.name}}</div>
                <div class="info">身份证：{{$store.state.accountInfo.sfz}}</div>
                <div class="info logout" @click="logout">退出</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {loginOut} from "r/index/login.js";

    export default {
        name: "Header",
        data() {
            return {}
        },
        methods:{
             logout(){
                this.$confirm('确认退出登录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    await loginOut()
                    this.$store.commit('loginOut')
                    this.$message.success("退出成功！");
                    await this.$router.replace("/login");
                }).catch(() => {
                    this.$message.info("已取消操作！");
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/base.scss";

    .header-box {
        font-family: "Microsoft YaHei";
        font-size: 14px;
        width: 100%;
        height: 82px;
        background-color: #ffffff;
        border-top: 8px solid var(--color);
        box-shadow: 0px 2px 4px #ccc;

        .header-content {
            width: 1100px;
            height: 80px;
            line-height: 80px;
            margin: 0 auto;
            padding: 0 20px;
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: space-between;

            .header-content-l,
            .header-content-r {
                display: flex;
                flex-flow: row;
                align-items: center;

                .img_logo {
                    float: left;
                    width: 200px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                ul {
                    margin: 0 0 0 57px;
                    padding: 0;

                    li {
                        list-style: none;
                        font-size: 16px;
                        color: var(--color);
                        border-bottom: 4px solid var(--color);
                        cursor: pointer;
                    }
                }

                .info {
                    margin-left: 20px;
                }

                .logout {
                    height: 30px;
                    line-height: 30px;
                    margin-left: 90px;

                    &:hover {
                        cursor: pointer;
                        color: var(--color);
                        font-weight: bold;
                    }
                }
            }
        }
    }
</style>
